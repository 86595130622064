import request from '../request'

//产品名称列表
export const product_index_request = p => {
    return request({
        method:'GET',
        url:'product/index',
        params: p
    })
}

//添加产品名称
export const product_add_request = d => {
    return request({
        method:'POST',
        url:'product/add',
        data: {
            with_client_id: d.with_client_id,
            name: d.name
        }
    })
}

//删除产品名称
export const product_del_request = id => {
    return request({
        method:'DELETE',
        url:'product/del',
        data: {
            id: id
        }
    })
}

//修改产品名称
export const product_edit_request = d => {
    return request({
        method:'PUT',
        url:'product/edit',
        data: d
    })
}

//获取产品名称详情
export const product_details_request = id => {
    return request({
        method:'GET',
        url:'product/details',
        params: {
            id
        }
    })
}