<template>
    <div>
        <loading-component></loading-component>
        <nav-bar-component :navbar="nav_bar" :title_name="my_nav_bar_name"></nav-bar-component>
        <div v-if="edit">
            <van-cell-group inset >
                <van-form>
                    <van-field
                        v-model="details.name"
                        name="name"
                        label="产品名称"
                        placeholder="客户名称"
                        :rules="[{ required: true, message: '请填写客户名称' }]"
                    />
                    <select-component
                        name="with_client_id"
                        lable="所属客户"
                        :farr="all_client"
                        :fvalue_name="details.with_client_name"
                        @clk="confirm_client"
                    ></select-component>
                </van-form>
                <div v-if="this.id">
                    <div class="sub_button">
                        <van-button round block type="primary" @click="edit_details">提 交</van-button>
                    </div>
                    <div class="sub_button">
                        <van-button round block type="default" @click="back_details">取 消</van-button>
                    </div>
                </div>
                <div v-else>
                    <div class="sub_button">
                        <van-button round block type="primary" @click="add_details">提 交</van-button>
                    </div>
                    <div class="sub_button">
                        <van-button round block type="default" @click="$router.go(-1)">返回</van-button>
                    </div>
                </div>
            </van-cell-group>
        </div>
        <div v-else>
            <van-cell-group inset >
                <van-cell title="产品名称" :value="details.name" />
                <van-cell title="客户名称" :value="details.with_client_name" />
            </van-cell-group>
            <div class="sub_button">
                <van-button v-if="this.$_has('修改产品')" round block type="info" @click="edit = true">修 改</van-button>
            </div>
            <div class="sub_button">
                <van-button v-if="this.$_has('删除产品')" round block type="danger" @click="on_del">删 除</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import NavBarComponent from '@/components/common/NavBarComponent.vue'
import SelectComponent from '@/components/common/SelectComponent.vue'
import NumberKeyboardComponent from '@/components/common/NumberKeyboardComponent.vue'

import { product_details_request,product_del_request,product_edit_request,product_add_request } from '@/network/WareHouse/product.js'
import { client_list_request } from '@/network/list.js'

export default {
    name:'ProductDetailsComponent',
    data(){
        return {
            nav_bar: {
                left: true,
                right: {
                    type: '',
                    name: ''
                },
            },
            id: 0,
            details: {
                name: '',
                with_client_id: '',
            },
            edit: false,
            all_client: []
        }
    },
    computed:{
        my_nav_bar_name() {
            return this.edit === true ? ( this.id ? '修改产品' : '新增产品') : '产品详情'
        }
    },
    methods:{
        get_all_client() {
            this.$store.commit('true_loading')
            client_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.all_client = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        get_product_details() {
            this.$store.commit('true_loading')
            product_details_request(this.id)
                .then((s) => {
                    if (s.status === 0) {
                        this.details = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        on_del() {
            this.$dialog.confirm({
                title: '温馨提示',
                message: '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
            })
            .then(() => {
                this.$store.commit('true_loading')
                product_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$toast.success('删除成功!')
                                this.$router.go(-1);//返回上一层
                            } else {
                                this.$toast.fail(s.msg)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                        .finally(() => this.$store.commit('false_loading'))
            })
            .catch(() => {});
        },
        confirm_client(e) {
            this.details.with_client_id = e.id
        },
        back_details() {
            this.get_product_details()
            this.edit = false
        },
        edit_details() {
            this.$store.commit('true_loading')
            let data = {}
            data = {
                id: this.id,
                name: this.details.name,
                with_client_id: this.details.with_client_id
            }
            product_edit_request(data)
                .then((s) => {
                    if (s.status === 0) {
                        this.$toast.success('修改成功!')
                        this.get_product_details()
                        this.edit = false
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        add_details() {
            this.$store.commit('true_loading')
            let data = {}
            data = {
                name: this.details.name,
                with_client_id: this.details.with_client_id
            }
            product_add_request(data)
                .then((s) => {
                    if (s.status === 0) {
                        this.$toast.success('添加成功!')
                        this.id = s.result.id
                        this.get_product_details()
                        this.edit = false
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => this.$store.commit('false_loading'))
        }
    },
    filters:{},
    props:{},
    created(){
        this.get_all_client()

        this.id = this.$route.query.id;
        if(this.id) {
            this.get_product_details()
        }
        else
        {
            this.edit = true
        }
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        LoadingComponent,
        NavBarComponent,
        SelectComponent,
        NumberKeyboardComponent
    },
    watch:{}
}
</script>

<style>
.sub_button {
    margin: 0.5rem;
}
</style>